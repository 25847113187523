.gridWidth {
  width: 20%;

  @media (min-width: 600px) and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.card {
  border-radius: 10px;
  background: #212121;
  box-shadow: 15px 15px 30px rgb(25, 25, 25),
    0px 0px 10px rgb(60, 60, 60);
  @media screen and (min-width: 600px) {
    display: none;
  }
}

.dot {
  margin-top: 10px;
}
